import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { User, UserModel } from "../models/user";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { getHeaders } from "../utils/headers.functions";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(
		private http: HttpClient,
		private router: Router
	) {
		this.currentUserSubject = new BehaviorSubject<User>(
			JSON.parse(localStorage.getItem("currentUser"))
		);
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	public login(username: string, password: string) {
		return this.http.post<any>(`${environment.apiUrl}/auth/signin`,
			{ email: username, password: password })
			.pipe(
				map((user) => {
					localStorage.setItem("currentUser", JSON.stringify(user));
					this.currentUserSubject.next(user);
					return user;
				})
			);
	}

	public signup(user: UserModel): Observable<any> {
		const headers: HttpHeaders = getHeaders();
		return this.http.post<any>(`${environment.apiUrl}/auth/signup`, user, { headers })
	}

	public validate(token: string): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/auth/validate-email`, { token: token });
	}

	public logout() {
		localStorage.removeItem("currentUser");
		this.currentUserSubject.next(null);
		return of({ success: false });
	}

	public handleUnauthorizedError(err: any) {
		if (err.error && err.error.status === 401) {
			this.logout();
			this.router.navigate(['/authentication/signin']);
		}
		return throwError('Session Expired');
	}

	public getUserById(id: string){
		const headers: HttpHeaders = getHeaders();
		return this.http.get<any>(`${environment.apiUrl}/user/${id}`, { headers })
	}

	public updateUser(id: string, user: any){
		const headers: HttpHeaders = getHeaders();
		return this.http.put<any>(`${environment.apiUrl}/user/${id}`, user, { headers })
	}

}

<div class="auth-container">
	<div class="row auth-main">
		<div class="col-sm-6 px-0 d-none d-sm-block">
			<div class="left-img" style="background-image: url(assets/images/pages/background.png);">
			</div>
		</div>
		<div class="col-sm-6 auth-form-section">
			<div class="form-section">
				<div class="auth-wrapper">
					<form>
						<span class="error-header p-b-45">
							404
						</span>
						<span class="error-subheader p-b-5">
							Parece que estás perdido/a
						</span>
						<span class="error-subheader2 p-b-5">
							¡La página que buscas no está disponible!
						</span>
						<div class="container-auth-form-btn mt-5">
							<a [routerLink]="'/authentication/signin'">
								<button mat-flat-button color="primary" class="auth-form-btn" type="submit">
									Ir a la página de inicio
								</button>
							</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      { path: "dashboard", loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule) },
      { path: "advance-table", loadChildren: () => import("./advance-table/advance-table.module").then((m) => m.AdvanceTableModule) },
      { path: "calendar", loadChildren: () => import("./calendar/calendar.module").then((m) => m.CalendarsModule) },
      { path: "task", loadChildren: () => import("./task/task.module").then((m) => m.TaskModule) },
      { path: "contacts", loadChildren: () => import("./contacts/contacts.module").then((m) => m.ContactsModule) },
      { path: "email", loadChildren: () => import("./email/email.module").then((m) => m.EmailModule) },
      { path: "calendar", loadChildren: () => import("./apps/apps.module").then((m) => m.AppsModule) },
      { path: "apps", loadChildren: () => import("./apps/apps.module").then((m) => m.AppsModule) },
      { path: "widget", loadChildren: () => import("./widget/widget.module").then((m) => m.WidgetModule) },
      { path: "ui", loadChildren: () => import("./ui/ui.module").then((m) => m.UiModule) },
      { path: "forms", loadChildren: () => import("./forms/forms.module").then((m) => m.FormModule) },
      { path: "tables", loadChildren: () => import("./tables/tables.module").then((m) => m.TablesModule) },
      { path: "media", loadChildren: () => import("./media/media.module").then((m) => m.MediaModule) },
      { path: "charts", loadChildren: () => import("./charts/charts.module").then((m) => m.ChartsModule) },
      { path: "timeline", loadChildren: () => import("./timeline/timeline.module").then((m) => m.TimelineModule) },
      { path: "icons", loadChildren: () => import("./icons/icons.module").then((m) => m.IconsModule) },
      { path: "account", loadChildren: () => import("./extra-pages/extra-pages.module").then((m) => m.ExtraPagesModule) },
      { path: "maps", loadChildren: () => import("./maps/maps.module").then((m) => m.MapsModule) },
      { path: "multilevel", loadChildren: () => import("./multilevel/multilevel.module").then((m) => m.MultilevelModule) },
      { path: "devices", loadChildren: () => import("./devices/devices.module").then((m) => m.DevicesModule) },
      { path: "farms", loadChildren: () => import("./farms/farms.module").then((m) => m.FarmsModule) },
      { path: "tasks", loadChildren: () => import("./operations/operations.module").then((m) => m.OperationsModule) },
      { path: "catalogs", loadChildren: () => import("./catalogs/catalogs.module").then((m) => m.CatalogsModule) },
      { path: "list", loadChildren: () => import("./lists/lists.module").then((m) => m.ListsModule) },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
